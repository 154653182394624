<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">工资包</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资单模板管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ type === "add" ? "新增" : "编辑" }}工资单模板</span>
      </div>
      <div>
        <el-upload
          style="text-align: left;"
          class="upload-demo"
          action
          :multiple="false"
          :limit="1"
          :auto-upload="false"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :on-change="importExcel"
          :file-list="uploadFiles"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
    </div>
    <el-card>
      <div style="margin-bottom: 20px">
        <Divider :title="'基本信息'"></Divider>
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="110px"
        >
          <el-form-item label="模板名称" label-width="110px" prop="title">
            <el-input v-model="title" style="width: 100%" placeholder="请输入模板名称"></el-input>
          </el-form-item>

          <el-form-item label="员工姓名" prop="name">
            <el-select
              v-model="ruleForm.name"
              filterable
              placeholder="请选择字段类目"
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="(item, index) in List"
                :key="index"
                :disabled="checkboxGroup.includes(item)"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCardNumber">
            <el-select
              v-model="ruleForm.idCardNumber"
              filterable
              placeholder="请选择字段类目"
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="(item, index) in List"
                :key="index"
                :disabled="checkboxGroup.includes(item)"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实发工资" prop="salary">
            <el-select
              v-model="ruleForm.salary"
              filterable
              placeholder="请选择字段类目"
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="(item, index) in List"
                :key="index"
                :disabled="checkboxGroup.includes(item)"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 上传工资单 -->
        <div class="block-header divider-warp">
          <div style="font-size: 15px;  color: #000;display:flex;align-items:end">
            字段类目信息
            <span
              class="tag"
            >工资单文件解析的字段类目，可进行选中和不选中切换，如果字段类目处于选中状态，员工查看工资单信息时可看到对应的信息内容，不选中则看不到</span>
          </div>

          <div>
            <span slot="right"></span>
          </div>
        </div>
        <p style="text-align:left;color:#999;font-size:14px" v-if="!fileName">请先选择工资单文件</p>
        <p style="text-align:left;color:#999;font-size:14px" v-else>
          {{ fileName }}
          <span style="color:#EC808D;margin:0 5px;" @click="getDel()">清除</span>
        </p>

        <div class="row_demo" v-if="checkboxGroup.length >= 3">
          <div v-for="(item, index) in List" :key="index">
            <el-button
              :type="checkboxGroup.includes(item) ? 'primary' : ''"
              :disabled="Object.values(ruleForm).includes(item)"
              @click="checkboxChange(item)"
            >{{ item }}</el-button>
          </div>
        </div>
        <!-- <el-checkbox-group
          v-model="checkboxGroup"
          size="mini"
          class="row_demo"
          @change="groupChange"
        >
          <div v-for="item in List" :key="item.key" style="margin:5px">
            <el-checkbox-button
              :label="item.value"
              @change="checkboxChange"
              :disabled="Object.values(ruleForm).includes(item.value)"
              border
            >{{item.value}}</el-checkbox-button>
          </div>
        </el-checkbox-group>-->
      </div>
      <div class="add_bottom">
        <el-button
          style="float:left;margin:0px 10px;"
          type="text"
          @click="getPreview()"
          size="small"
          plain
        >预览</el-button>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 20px"
          @click="submitForm('ruleForm')"
        >保存</el-button>
        <el-button type="info" @click="goBack" size="small" plain>取消</el-button>
      </div>
      <span style="float:right;margin:-20px 0 10px 10px;color:#999;font-size:14px">
        员工可查看{{
        checkboxGroup.length
        }}个字段类目信息内容
      </span>
    </el-card>
    <el-dialog title="工资详情" :visible.sync="dialogVisible" width="30%" style="padding:0">
      <div class="mb_view">
        <div class="card-top" style="padding:15px 0;margin: 0 10px;">
          <div class="card-left">
            <span></span>
            <span>工资详情</span>
          </div>
        </div>
        <div class="card-demo" v-for="(item, index) in checkboxGroup" :key="index">
          <span>{{ item }}</span>
          <span>xxxx</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import * as _ from 'lodash';
import { api } from '/src/api/base';

export const projectInfo = api()('project.info.json');
export const taskTamplateAdd = api()('taskTamplate.add.json');
export const taskTamplateEdit = api()('taskTamplate.edit.json');
export const salaryRegistrationCompare = api()(
  'salaryRegistration.compare.json'
);
export const taskTamplateInfo = api()('taskTamplate.info.json');

export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      type: '',
      code: '',
      projectData: [],
      List: [],
      project: '',
      enterpriseName: '',
      checked: [],
      dialogVisible: false,
      checkboxGroup: [],
      title: '',
      ruleForm: {
        name: '',
        idCardNumber: '',
        salary: ''
      },
      rules: {

        name: [
          { required: true, message: '请选择员工姓名类目', trigger: 'blur' },
        ],
        idCardNumber: [
          { required: true, message: '请选择身份证号类目', trigger: 'blur' },
        ],
        salary: [
          { required: true, message: '请选择实发工资类目', trigger: 'blur' },
        ],
        remark: [],
      },
      uploadFiles: [],
      state: '',
      fileName: '',
    };
  },

  async created () {
    this.type = this.$route.query.type;
    if (this.type === 'add') {
      this.enterpriseName = localStorage.getItem('enterpriseName');

    }
    if (this.type === 'edit') {
      this.code = this.$route.query.code;
      this.enterpriseName = localStorage.getItem('enterpriseName');
      this.getDetail();
    }

  },
  methods: {

    async change () {
      const arr = [...Object.values(this.ruleForm), ...this.checkboxGroup]

      this.checkboxGroup = _.uniq(arr).filter(n => n)

    },
    getDel () {
      this.checkboxGroup = [],
        this.ruleForm.name = '',
        this.ruleForm.idCardNumber = '',
        this.ruleForm.salary = ''
      this.fileName = '',
        this.List = []
    },
    getPreview () {
      this.dialogVisible = true
    },
    checkboxChange (a) {
      this.checkboxGroup.includes(a) ? (this.checkboxGroup = this.checkboxGroup.filter(n => n !== a)) : this.checkboxGroup.push(a)
    },

    async getDetail () {
      const rs = await taskTamplateInfo({
        code: this.code,
      });
      Object.keys(this.ruleForm).forEach((it) => {
        this.ruleForm[it] = rs[it];
      });
      this.title = rs.title
      this.List = rs.baseTags.split(',')
      this.checkboxGroup = rs.selectTags.split(',')
    },


    goBack () {
      window.history.back(-1);
    },
    submitForm (formName) {
      if (this.title) {
        this.$refs[formName].validate(async (valid) => {
          const params = {
            ...(this.type === 'edit' ? { code: this.code } : {}),
            ...this.ruleForm,
            title: this.title,
            baseTags: this.List,
            selectTags: this.checkboxGroup
          };
          if (valid) {
            if (this.type === 'add') {
              await taskTamplateAdd(params);
            }
            if (this.type === 'edit') {
              await taskTamplateEdit(params);
            }
            this.$refs[formName].resetFields();
            this.$router.push({
              path: '/system/task/taskTemplate',
            });
          } else {
            return false;
          }
        });
      }
      else {
        this.$message({
          message: '警告哦，模板标题不为空',
          type: 'warning'
        });
      }
    },

    // 导入表格
    async importExcel (param) {
      this.List = []
      this.uploadFiles = [];
      this.ruleForm.name = '',
        this.ruleForm.idCardNumber = '',
        this.ruleForm.salary = ''
      this.fileName = '',
        this.fileName = param.name;
      const arr = await this.getExcelHeaders(param)

      console.log(this.List);
      if (_.uniq(arr).filter(n => n).length != arr.length) {
        this.$message({
          message: '警告哦，表格中不能有重复表头或者空表头',
          type: 'warning'
        });
      }
      else {
        arr.forEach((item,) => {
          this.List.push(item)
        })
      }
    },

    getExcelHeaders (file) {
      const getHeaderRow = (sheet) => {
        const headers = []

        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = range.s.c
        for (C = range.s.c; C <= range.e.c; ++C) {
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: 0 })]
          if (cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell))
          }
        }
        return headers
      }
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;

          this.wb = XLSX.read(data, {
            type: 'binary',
          });

          resolve(getHeaderRow(this.wb.Sheets.Sheet1 || this.wb.Sheets.sheet1));
        };
        reader.readAsBinaryString(file.raw);
      });
    },
  },
};
</script>
<style>
.el-button--primary.is-plain.is-disabled,
.el-button--primary.is-plain.is-disabled:active,
.el-button--primary.is-plain.is-disabled:focus,
.el-button--primary.is-plain.is-disabled:hover {
  background: #409eff;
  color: #fff;
}
</style>
<style lang="scss" scoped>
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;

  .header_top {
    display: flex;
    align-items: center;

    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }

    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }

    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}

.add_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}

.inputNumberContent {
  background: cornflowerblue !important;
}

.row_demo {
  display: flex;
  align-items: center;
  justify-content: space-around;

  flex-wrap: wrap;
}

.block-header {
  margin: 35px 0 20px;
  font-weight: 400;
  color: #1f2f3d;
  background: #ecf8ff;
  /* border: 1px solid #ecf8ff; */
  border-left: 5px solid #50bfff;
  padding: 5px 10px;
  /* border-radius: 4px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ecf5ff;
  border-left: 5px solid #409eff;
}

.divider-horizontal {
  display: block;
  height: 1px;
  width: 100%;
  /* margin: 24px 0; */
  margin: 36px 0 24px 0;
  background-color: #dcdfe6;
  position: relative;
}

.divider-title {
  left: 20px;
  transform: translateY(-50%);
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  color: #303133;
  font-weight: 500;
  font-size: 14px;
}

.divider-operate {
  right: 20px;
  transform: translateY(-50%);
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  color: #303133;
  font-weight: 500;
  font-size: 14px;
}

.tag {
  font-size: 13px;
  color: #ec808d;
  margin-left: 10px;
}

.row_demo {
  margin: 10px 0;
}

.mb_view {
  height: 790px !important;
  transform: scale(0.8);
  width: 414px;
  transform-origin: top center;
  margin: 0 auto;
  background: url(https://www.lgbfss.com/web/img/iphone-device-skin.10558c1b.png);
  background-size: 100%;
  padding: 61px 16px;
  overflow: hidden;
}

.card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px;
  border-bottom: 1px solid #f7f7f7;

  .card-left {
    span:nth-child(1) {
      border: 2px solid #6db5fb;
    }

    span:nth-child(2) {
      margin-left: 10px;
      font-size: 14px;
      color: #000;
      font-weight: 700;
    }
  }
}

.card-demo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
}
</style>
